<template>
    <el-container>
        <el-header>
            <el-row>
                <el-col :span="6">
                    <span style="font-size: 18px">Пользователи</span>
                </el-col>
                <el-col :span="18" align="right">
                    <el-button v-if="$can('projects.write')" @click="newRow()" type="success" icon="el-icon-plus">Создать</el-button>
                </el-col>
            </el-row>
        </el-header>

        <el-main>
            <el-table :data="dataRows">
                <el-table-column prop="name" label="Name">
                </el-table-column>
                <el-table-column prop="email" label="Email">
                </el-table-column>
                <el-table-column prop="role" label="Role">
                </el-table-column>
                <el-table-column label="Actions">
                    <template slot-scope="scope">
                        <el-button-group style="font-size: 20px">
                            <i v-if="$can('projects.write')" @click="editRow(scope.$index)" class="el-icon-edit" style="color: blue"></i>
                            <el-popconfirm v-if="$can('projects.write')" @confirm="deleteRow(scope.$index)" title="Точно хотите удалить?"
                                confirm-button-text="Да" cancel-button-text="Нет">
                                <i slot="reference" style="color: red" class="el-icon-delete"></i>
                            </el-popconfirm>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog v-if="rowObject != null" title="Создать/Изменить" :visible.sync="createDialogVisible">
                <el-form :model="rowObject" label-width="160px">
                    <el-form-item label="Name">
                        <el-input v-model="rowObject.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Email">
                        <el-input v-model="rowObject.email" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Role">
                        <el-select v-model="rowObject.role">
                            <el-option v-for="item in roles" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Password">
                        <el-input v-model="rowObject.password" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="createDialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="saveRow()">Save</el-button>
                </span>
            </el-dialog>

        </el-main>
    </el-container>
</template>

<script>
import { users } from "../../api_connectors";

export default {
    name: "UsersIndex",
    data() {
        return {
            dataRows: [],
            rowObject: null,
            createDialogVisible: false,
            roles: [],
        };
    },
    mounted() {
        this.getData();
        this.getRoles();
    },
    methods: {
        getData() {
            users
                .list()
                .then((response) => {
                    this.dataRows = response.data;
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        getRoles() {
            users
                .roles()
                .then((response) => {
                    this.roles = response.data;
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        newRow() {
            this.rowObject = {
                name: null,
                email: null,
                password: null,
            };
            this.createDialogVisible = true;
        },
        editRow($index) {
            this.rowObject = this.dataRows[$index];
            this.createDialogVisible = true;
        },
        saveRow() {
            this.$loading();
            let result =
                this.rowObject.id > 0
                    ? users.update(
                        this.rowObject.id,
                        this.rowObject
                    )
                    : users.create(this.rowObject);
            result
                .then((response) => {
                    this.$loading().close();
                    this.$message({
                        message: "Успешное сохранение!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                    this.getData();
                    this.createDialogVisible = false;
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        deleteRow($index) {
            this.$loading();
            users
                .delete(this.dataRows[$index].id)
                .then((response) => {
                    this.getData();
                    this.$loading().close();
                    this.$message({
                        message: "Успешное удаление!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },

    },
};
</script>

<style scoped>

</style>
