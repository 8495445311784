<template>
    <el-container>
        <el-header>
            <el-row>
                <el-col :span="6">
                    <span style="font-size: 18px">Отпуска</span>
                </el-col>
                <el-col :span="18" align="right">
                    <el-button v-if="$can('employee_vacations.write')" @click="newRow()" type="success"
                        icon="el-icon-plus">Создать</el-button>
                </el-col>
            </el-row>
        </el-header>

        <el-main>
            <el-table :data="dataRows">
                <el-table-column prop="employee.name" label="Сотрудник">
                </el-table-column>
                <el-table-column prop="vacation_start" label="Начало">
                </el-table-column>
                <el-table-column prop="vacation_end" label="Конец">
                </el-table-column>
                <el-table-column prop="vacation_duration" label="Длительность">
                </el-table-column>
                <el-table-column prop="payment_type" label="Тип оплаты">
                </el-table-column>
                <el-table-column prop="comment" label="Комментарий">
                </el-table-column>
                <el-table-column label="Actions">
                    <template slot-scope="scope">
                        <el-button-group style="font-size: 20px">
                            <i v-if="$can('employees.write')" @click="editRow(scope.$index)" class="el-icon-edit"
                                style="color: blue"></i>
                            <el-popconfirm v-if="$can('employees.write')" @confirm="deleteRow(scope.$index)"
                                title="Точно хотите удалить?" confirm-button-text="Да" cancel-button-text="Нет">
                                <i slot="reference" style="color: red" class="el-icon-delete"></i>
                            </el-popconfirm>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog v-if="rowObject != null" title="Создать/Изменить" :visible.sync="createDialogVisible">
                <el-form :model="rowObject" label-width="160px">
                    <el-form-item label="Employee">
                        <el-select v-model="rowObject.employee_id">
                            <el-option v-for="item in employees" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Vacation start">
                        <el-date-picker v-model="rowObject.vacation_start" type="date" placeholder="Pick a day"
                            format="dd.MM.yyyy" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Vacation end">
                        <el-date-picker v-model="rowObject.vacation_end" type="date" placeholder="Pick a day"
                            format="dd.MM.yyyy" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Vacation duration">
                        <el-input v-model="rowObject.vacation_duration">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Тип оплаты">
                        <el-select v-model="rowObject.payment_type">
                            <el-option label="За свой счет" value="at_own_expense"></el-option>
                            <el-option label="Выплачен отдельно" value="paid_separately"></el-option>
                            <el-option label="Выплачен в з/п" value="paid_in_salary"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Кооментарий">
                        <el-input v-model="rowObject.comment" type="textarea" :rows="2">
                        </el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="createDialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="saveRow()">Save</el-button>
                </span>
            </el-dialog>

        </el-main>
    </el-container>
</template>

<script>
import { employees, employeeVacations } from "../../api_connectors";

export default {
    name: "EmployeeVacationsIndex",
    data() {
        return {
            dataRows: [],
            employees: [],
            rowObject: null,
            createDialogVisible: false,
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            let promises = [];
            promises.push(employees.list());
            promises.push(employeeVacations.list());

            Promise.all(promises)
                .then((response) => {
                    this.employees = response[0].data;
                    this.dataRows = response[1].data;
                    //console.log(response);
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        newRow() {
            this.rowObject = {
                employee_id: null,
                vacation_start: null,
                vacation_end: null,
                vacation_duration: null,
                payment_type: null,
                comment: null
            };
            this.createDialogVisible = true;
        },
        editRow($index) {
            this.rowObject = this.dataRows[$index];
            this.createDialogVisible = true;
        },
        saveRow() {
            this.$loading();
            let result =
                this.rowObject.id > 0
                    ? employeeVacations.update(
                        this.rowObject.id,
                        this.rowObject
                    )
                    : employeeVacations.create(this.rowObject);
            result
                .then((response) => {
                    this.$loading().close();
                    this.$message({
                        message: "Успешное сохранение!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                    this.getData();
                    this.createDialogVisible = false;
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        deleteRow($index) {
            this.$loading();
            employees
                .delete(this.dataRows[$index].id)
                .then((response) => {
                    this.getData();
                    this.$loading().close();
                    this.$message({
                        message: "Успешное удаление!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },

    },
};
</script>

<style scoped></style>
