<template>
    <el-container>
        <el-main class="outer">


            <el-card class="box-card">
                <el-form :model="transactionObject" label-width="160px">
                    <el-form-item label="In/Out">
                        <el-radio v-model="transactionObject.financial_type" label="out">Expense</el-radio>
                        <el-radio v-model="transactionObject.financial_type" label="in">Income</el-radio>
                    </el-form-item>
                    <el-form-item label="Amount">
                        <el-input v-model="transactionObject.amount" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Description">
                        <el-input v-model="transactionObject.description" autocomplete="off" type="textarea" autosize>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Contragent">
                        <el-input v-model="transactionObject.contragent"></el-input>
                    </el-form-item>
                    <el-form-item label="Date">
                        <el-input v-model="transactionObject.date"></el-input>
                    </el-form-item>
                    <el-form-item label="Type">
                        <el-select v-model="transactionObject.type">
                            <el-option label="Other" value="other"></el-option>
                            <el-option label="Tech" value="tech"></el-option>
                            <el-option label="Staff" value="staff"></el-option>
                            <el-option label="Contragents" value="contragents"></el-option>
                            <el-option label="Office" value="office"></el-option>
                            <el-option label="Marketing" value="marketing"></el-option>
                            <el-option label="Taxes" value="taxes"></el-option>
                            <el-option label="Dividends" value="dividends"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Money Source">
                        <el-select v-model="transactionObject.money_source">
                            <el-option label="Account" value="account"></el-option>
                            <el-option label="Founder" value="stack_holder"></el-option>
                            <el-option label="Employee" value="employee"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Employee"
                        v-if="(transactionObject.type == 'staff') || (transactionObject.money_source == 'employee')">
                        <el-select v-model="transactionObject.employee_id">
                            <el-option v-for="item in employees" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Founder"
                        v-if="(transactionObject.money_source == 'stack_holder') || (transactionObject.type == 'dividends')">
                        <el-select v-model="transactionObject.stack_holder_id">
                            <el-option v-for="item in stackHolders" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Project">
                        <el-select v-model="transactionObject.project_id">
                            <el-option v-for="item in projects" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Calculation Period">
                        <el-select v-model="transactionObject.calculation_period_id">
                            <el-option v-for="item in calculationPeriods" :key="item.id" :label="item.period_short"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button type="primary" @click="saveTransaction()">Save</el-button>
                    </el-form-item>
                </el-form>
            </el-card>


        </el-main>
    </el-container>
</template>

<script>
import { transactions } from "../../api_connectors";
import { projects } from "../../api_connectors";
import { stackHolders } from "../../api_connectors";
import { employees } from "../../api_connectors";
import { calculationPeriods } from "../../api_connectors";

export default {
    name: "NewIndex",
    data() {
        return {
            stackHolders: [],
            calculationPeriods: [],
            employees: [],
            projects: [],
            transactionObject: {
                external_id: null,
                amount: 0,
                description: "",
                contragent: '',
                date: "",
                project_id: null,
                calculation_period_id: null,
                money_source_id: null,
                type: "other",
                money_source: "account",
                financial_type: "out"
            },
        };
    },
    mounted() {
        this.getEnums();
    },
    methods: {
        getEnums() {
            let promises = [];
            promises.push(stackHolders.list());
            promises.push(calculationPeriods.list(true));
            promises.push(employees.list());
            promises.push(projects.list());

            Promise.all(promises)
                .then((response) => {
                    this.stackHolders = response[0].data;
                    this.calculationPeriods = response[1].data;
                    this.employees = response[2].data;
                    this.projects = response[3].data;
                    console.log(this.transactions);
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        saveTransaction() {
            this.$loading();
            transactions
            .create(this.transactionObject)
                .then((response) => {
                    this.$loading().close();
                    this.$message({
                        message: "Успешное сохранение!",
                        type: "success",
                        duration: 30000,
                        showClose: true,
                    });
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
.outer {
    position: relative;
}

.box-card {
    width: 90%;
    position: absolute;
    top: 5%;
    left: 5%;
}
</style>
