<template>
    <el-container>
        <el-header>
            <el-row>
                <el-col :span="6">
                    <span style="font-size: 18px">Расчетные периоды</span>
                </el-col>
                <el-col :span="18" align="right">
                    <el-button v-if="$can('calculation_periods.write')" @click="newRow()" type="success"
                        icon="el-icon-plus">Создать</el-button>
                </el-col>
            </el-row>
        </el-header>

        <el-main>
            <el-table :data="dataRows">
                <el-table-column prop="period_short" label="Period">
                </el-table-column>
                <el-table-column label="Actions">
                    <template slot-scope="scope">
                        <el-button-group style="font-size: 20px" v-if="$can('calculation_periods.write')">
                            <i v-if="!dataRows[scope.$index].closed" @click="closePeriod(scope.$index, scope.row.id)"
                                class="el-icon-circle-check" style="color: green"></i>
                            <a v-if="dataRows[scope.$index].closed"
                                :href="'/api/calculation-periods/' + dataRows[scope.$index].id + '/get-acts'"
                                target="_blanc"><i v-if="$can('employees.read')" class="el-icon-document"
                                    style="color: gray"></i></a>
                            <a v-if="!dataRows[scope.$index].closed"
                                :href="'/api/calculation-periods/' + dataRows[scope.$index].id + '/get-acts'"
                                target="_blanc"><i v-if="$can('employees.read')" class="el-icon-document"
                                    style="color: red"></i></a>

                            <i @click="editRow(scope.$index)" class="el-icon-edit" style="color: blue"></i>
                            <el-popconfirm @confirm="deleteRow(scope.$index)" title="Точно хотите удалить?"
                                confirm-button-text="Да" cancel-button-text="Нет">
                                <i slot="reference" style="color: red" class="el-icon-delete"></i>
                            </el-popconfirm>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog v-if="rowObject != null" title="Создать/Изменить" :visible.sync="createDialogVisible">
                <el-form :model="rowObject" label-width="160px">
                    <el-form-item label="Period">
                        <el-date-picker v-model="rowObject.period" firstDayOfWeek="1" type="month" placeholder="Pick a day"
                            format="MM.yyyy" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Previous period">
                        <el-select v-model="rowObject.previous_period_id">
                            <el-option v-for="item in dataRows" :key="item.id" :label="item.period_short" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="createDialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="saveRow()">Save</el-button>
                </span>
            </el-dialog>

        </el-main>
    </el-container>
</template>

<script>
import { calculationPeriods } from "../../api_connectors";

export default {
    name: "ProjectsIndex",
    data() {
        return {
            dataRows: [],
            rowObject: null,
            createDialogVisible: false,

        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            calculationPeriods
                .list()
                .then((response) => {
                    this.dataRows = response.data;
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        newRow() {
            this.rowObject = {
                name: null,
            };
            this.createDialogVisible = true;
        },
        closePeriod($index, id) {
            this.$loading();
            calculationPeriods.close(id).then((res) => {
                this.getData();
                this.$loading().close();
                this.$message({
                    message: "Месяц успешно закрыт!",
                    type: "success",
                    duration: 3000,
                    showClose: true,
                });
                console.log("closed = ", this.dataRows[$index].closed);
            })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось закрыть период: " + (error.response.data.message || error.message),
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        editRow($index) {
            this.rowObject = this.dataRows[$index];
            this.createDialogVisible = true;
        },
        saveRow() {
            this.$loading();
            let result =
                this.rowObject.id > 0
                    ? calculationPeriods.update(
                        this.rowObject.id,
                        this.rowObject
                    )
                    : calculationPeriods.create(this.rowObject);
            result
                .then((response) => {
                    this.$loading().close();
                    this.$message({
                        message: "Успешное сохранение!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                    this.getData();
                    this.createDialogVisible = false;
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        deleteRow($index) {
            this.$loading();
            calculationPeriods
                .delete(this.dataRows[$index].id)
                .then((response) => {
                    this.getData();
                    this.$loading().close();
                    this.$message({
                        message: "Успешное удаление!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },

    },
};
</script>

<style scoped></style>
